<template>
	<div class="doctor-list">
		<el-card class="filter-container" shadow="never">
			<div style="margin-top: 15px">
				<el-form :inline="true" size="small" label-width="100px">
                    <el-row>
                        <el-form-item label="广告名称：">
                            <el-input style="width:300px" v-model="keyword" placeholder="广告名称"></el-input>
                        </el-form-item>

                        <el-form-item style="float: right;">
                            <el-button type="primary" size="small" @click="getonelist()">
                                查询
                            </el-button>
                            <el-button type="info" size="small"  @click="clearlist">
                                重置
                            </el-button>
                            <el-button v-else-if=""  type="success" size="small"  @click="goplugdetails()">
                                新增
                            </el-button>
                        </el-form-item>

                    </el-row>

				</el-form>
			</div>
		</el-card>

		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>广告列表</span></el-col>
			</el-row>
		</el-card>

		<el-card style="margin: 20px 0; " shadow="never">
			<el-table :data="list" style="width: 100%;">
				<el-table-column label="id" align="center" prop="plugAdId">
				</el-table-column>
			

				<el-table-column label="广告名称" align="center" prop="plugAdName"></el-table-column>
				
				<el-table-column label="所属位置" align="center" prop="plugAdtypeName"></el-table-column>
				
				<el-table-column label="添加时间" align="center" prop="plugAdAddtime"></el-table-column>
				
				<el-table-column label="排序" align="center">
					<template slot-scope="scope">
						<el-input style="width: 80px;" v-model="scope.row.plugAdOrder" @blur="blur(scope)"></el-input>
					</template>
				</el-table-column>
				
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.plugAdOpen" @change="switchbtn(scope)">
						</el-switch>
					</template>
				</el-table-column>
				
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click.native.prevent="goplugdetails(scope)">修改</el-button>
							<el-button size="mini" type="text" @click.native.prevent="del(scope)">删除</el-button>
						</p>
					</template>
				</el-table-column>
			</el-table>
		</el-card>


	
		<el-pagination v-if="pageshow" :hide-on-single-page="hideonsinglepage" background :current-page="pageNo"
		 @current-change="pagebtn" :total="box.totalCount" :page-size="10" layout="prev, pager, next">
		</el-pagination>

	</div>
</template>

<script>
	export default {
		data() {
			return {

				
				
				keyword: '', //搜索
				list: [], //循环列表 
				row: { //点击选中的
					plugAdtypeId: '',
					plugAdtypeName: '',
					plugAdtypeOrder: '',
				},
				box: [], //全部数据
				hideonsinglepage: false, //分页
				allocDialogVisible: false, //控制弹窗显示
				modalAppendToBody: false, //弹窗插入位置
				apiurl: '', //接口地址
				pageNo: 1, //页码
				pageshow: true
			}
		},
		methods: {
	
			// 查询
			getonelist(){
				const that=this;
				that.list=[];
				that.pageNo=1;
				that.getlist();
			},
	
			// 删除
			del(e) {
				const that = this;
                this.$confirm('您确认要删除该条广告信息么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api('/admin/plug/del', {
                        plugAdId: e.row.plugAdId
                    }).then(res => {
                        if (res.code == 200) {
                            that.$message.success("删除成功!");
                            that.getlist();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

			},

	
			// 取消
			clearshow() {
				const that = this;
				that.allocDialogVisible = false;
			},
			// 单条排序
			blur(e) {
				const that = this;
				var data = [{
					plugAdId: e.row.plugAdId,
					plugAdOrder: Number(e.row.plugAdOrder)
				}]

				that.api('/admin/plug/order', data).then(res => {
					if (res.code == 200) {
						that.getlist();
					}
				})
			},
			// 页数改变
			pagebtn(e) {
				const that = this;
				that.pageNo = e;
				that.getlist();
			},
			// 按钮开关
			switchbtn(e){
				const that=this;
				that.api('/admin/plug/state',{plugAdId:e.row.plugAdId}).then(res=>{
					if(res.code==200){
						that.getlist();
					}
				})
			},

			// 修改
			goplugdetails(e) {
				const that = this;

				that.$router.push({name: 'plugadadd',query:{plugAdId: e ?  e.row.plugAdId : ''}})
			},
			// 获取列表
			getlist() {
				const that = this;
				that.api('/admin/plug/list', {
					pageSize: 10,
					pageNo: that.pageNo,
					plugAdName: that.keyword
				}).then(res => {
					that.box = res.data;
					for(var i =0; i<res.data.pageList.length;i++){
						if(res.data.pageList[i].plugAdOpen=='1'){
							res.data.pageList[i].plugAdOpen=true
						}else{
							res.data.pageList[i].plugAdOpen=false
						}
					}					
					that.list = res.data.pageList;
					that.pageNo = res.data.pageNo;
					this.pageshow = false; //让分页隐藏
					this.$nextTick(() => { //重新渲染分页
						this.pageshow = true;
					});
				})
			},
			// 重置搜索
			clearlist() {
				const that = this;
				that.keyword = "";
				that.pageNo=1;
				that.getlist();
			}
		},
		created() {
			const that = this;
			that.getlist();
		}
	}
</script>

<style lang="less" scoped>


</style>
